import { render, staticRenderFns } from "./_point-totals.vue?vue&type=template&id=7978ec4a&scoped=true"
import script from "./_point-totals.vue?vue&type=script&lang=js"
export * from "./_point-totals.vue?vue&type=script&lang=js"
import style0 from "./_point-totals.vue?vue&type=style&index=0&id=7978ec4a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7978ec4a",
  null
  
)

export default component.exports